/**
 * Log levels
 */
export enum LogLevels {
  DEBUG = 10,
  INFO = 20,
  AUDIT = 30,
  WARN = 40,
  ERROR = 50,
  FATAL = 60,
}
