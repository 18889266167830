import { LoggerOptions } from './types.js';
import { initBaseLogObject } from './baseLogObject.js';

const aptRegex =
  /(apt|bldg|dept|fl|hngr|lot|pier|rm|ste|slip|trlr|unit|#)\.? *[a-z0-9-]+\b/gi;
const poBoxRegex = /P\.? ?O\.? *Box +\d+/gi;
const roadRegex =
  /(street|st|road|rd|avenue|ave|drive|dr|loop|court|ct|circle|cir|lane|ln|boulevard|blvd|way)\.?\b/gi;

const piiPatterns = {
  creditCardNumber:
    /\d{4}[ -]?\d{4}[ -]?\d{4}[ -]?\d{4}|\d{4}[ -]?\d{6}[ -]?\d{4}\d?/g,
  streetAddress: new RegExp(
    `(\\d+\\s*(\\w+ ){1,2}${roadRegex.source}(\\s+${aptRegex.source})?)|(${poBoxRegex.source})`,
    'gi',
  ),
  // zipcode: /\b\d{5}\b(-\d{4})?\b/g,
  phoneNumber:
    /(\(?\+?[0-9]{1,2}\)?[-. ]?)?(\(?[0-9]{3}\)?|[0-9]{3})[-. ]?([0-9]{3}[-. ]?[0-9]{4}|\b[A-Z0-9]{7}\b)/g,
  ipAddress:
    /(\d{1,3}(\.\d{1,3}){3}|[0-9A-F]{4}(:[0-9A-F]{4}){5}(::|(:0000)+))/gi,
  usSocialSecurityNumber: /\b\d{3}[ -.]\d{2}[ -.]\d{4}\b/g,
  emailAddress: /([a-z0-9_\-.+]+)@\w+(\.\w+)*/gi,
  // username: /(user( ?name)?|login): \S+/gi,
  password: /(pass(word|phrase)?|secret): \S+/gi,
  credentials:
    /(login( cred(ential)?s| info(rmation)?)?|cred(ential)?s) ?:\s*\S+\s+\/?\s*\S+/gi,
  // digits: /\b\d{4,}\b/g,
  // url: /([^\s:/?#]+):\/\/([^/?#\s]*)([^?#\s]*)(\?([^#\s]*))?(#([^\s]*))?/g,
};

const baseObjectKeys: Array<string> = Object.keys(
  initBaseLogObject({} as Omit<LoggerOptions, 'transport'>),
);

const isBaseLogSchemaKey = (objectKey: string): boolean =>
  baseObjectKeys.includes(objectKey);

const replacer = <T>(key: string, value: T): T | string => {
  // fix: Error objects doesn't have enumberable properties so JSON.stringify will not be able to get name, message, stack properties
  if (value instanceof Error) {
    return {
      ...value,
      name: value.name,
      message: value.message,
      stack: value.stack,
    };
  }

  if (!key || typeof value !== 'string' || isBaseLogSchemaKey(key))
    return value;
  return Object.values(piiPatterns).reduce(
    (redactedString, piiPattern) =>
      redactedString.replace(piiPattern, '[Redacted]'),
    value as string,
  );
};

/**
 * redact PII from a Javascript object
 * @param data Javascript object to redact PII from
 * @returns redacted object
 */
export const redactPii = <T>(data: T): T => {
  try {
    const redactedData = JSON.stringify(data, replacer);
    return JSON.parse(redactedData) as T;
  } catch (err) {
    return data;
  }
};
