import { Logger } from './types.js';
const ERR_CODE = 'apperr000';

/**
 * log unhandled errors and promise rejections
 * @param logger instance of logger
 */
export const logUnhandledErrors = (logger: Logger) => {
  if (!logger) return;
  window.addEventListener(
    'unhandledrejection',
    (event: PromiseRejectionEvent) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      logger.fatal({ errorCode: ERR_CODE, message: event.reason });
    },
  );

  window.addEventListener('error', (event: ErrorEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { message, filename = '', lineno = '', colno = '', error } = event;
    if (message.toLowerCase().indexOf('script error') > -1) {
      // eslint-disable-next-line no-console
      console.log('Script Error: See Browser Console for Detail');
    } else {
      const logMsg = {
        message,
        filename,
        lineno,
        colno,
        error: JSON.stringify(error),
      };
      logger.fatal({ errorCode: ERR_CODE, ...logMsg });
    }
  });
};
