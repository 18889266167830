import {
  onLCP,
  onFID,
  onCLS,
  LCPReportCallback,
  CLSReportCallback,
  FIDReportCallback,
} from 'web-vitals';
import { Logger } from './types.js';

/**
 * log web vitals such as CLS, FID and LCP
 * @param logger instance of logger
 */
export const webvitals = (logger: Logger): void => {
  const sendToAnalytics = (metric: unknown) => {
    logger.info({
      message: 'performance metrics',
      webvitals: metric,
    });
  };
  onCLS(sendToAnalytics as CLSReportCallback);
  onFID(sendToAnalytics as FIDReportCallback);
  onLCP(sendToAnalytics as LCPReportCallback);
};
