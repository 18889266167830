import { Transport, LogMessage } from '../types.js';
import fetchRetry from './fetch-retry.js';

/**
 * get http transport handler
 * @param url diagnostics endpoint url. e.g. https://api.ellielabs.com/diagnostics/v2/logging
 * @returns http transport handler
 */
export const http = (url: string): Transport => ({
  log: async (msg: LogMessage) => {
    // added compatibility for react native
    if (window && window.navigator && navigator.sendBeacon) {
      return navigator.sendBeacon(url, JSON.stringify(msg));
    }
    // fall back to fetch
    return fetchRetry({
      url,
      options: {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain',
        },
        keepalive: true,
        body: JSON.stringify(msg),
      },
      retries: 3,
    }).catch(console.error); // eslint-disable-line no-console
  },
});
