import { LogLevels } from './logLevel.js';
import { initBaseLogObject } from './baseLogObject.js';
import Console from './transport/console.js';
import { validateOptions, validateMessage } from './validations.js';
import { redactPii } from './redactPII.js';
import {
  Logger,
  LoggerOptions,
  LogFunctionWithLogLevel,
  LogMessage,
  LogRecord,
  RuntimeLoggerOptions,
} from './types.js';

const patchLog = (logMessage: string | LogRecord): string | LogRecord => {
  if (typeof logMessage === 'object' && !logMessage.message && logMessage.msg) {
    logMessage.message = logMessage.msg as string;
  }
  return logMessage;
};

/**
 * create logger
 * @param options {@link LoggerOptions}
 * @returns instance of logger
 * @example
 * 
 * // assuming pui-diagnostics javascript is added already to your HTML document
 * const { logger, http, logUnhandledErrors, webvitals } = window.emuiDiagnostics || {};
 * if (logger) {
 *   const appLogger = logger({
 *     transport: http('https://int.api.ellielabs.com/diagnostics/v2/logging'),
 *     index: 'myapp',
 *     team: 'my team',
 *     appName: 'Hello World App',
 *   });
 
 *   webvitals(appLogger);
 *   logUnhandledErrors(appLogger);
 *   appLogger.info('application launched');
 * } else console.warn('ui logger missing'); 
 */
export const logger = (options: LoggerOptions): Logger => {
  if (!options) throw new Error('options are required');
  const { transport = Console(), logEverythingToConsole } = options;
  validateOptions({ ...options, transport });
  let curLogLevel = LogLevels.INFO;
  const baseLogObject = initBaseLogObject(options);

  /**
   * log data
   * @param logLevel {@link LogLevels}
   * @param logData data to log. can be a string or an object
   */
  const log: LogFunctionWithLogLevel = (logLevel, logData) => {
    const message = patchLog(logData);
    validateMessage(message);
    const logMessage = typeof message === 'string' ? { message } : message;
    if (logLevel >= curLogLevel) {
      const msg = redactPii({
        ...baseLogObject,
        level: LogLevels[logLevel].toLowerCase(),
        '@timestamp': new Date().toISOString(),
        ...logMessage,
      } as LogMessage);
      transport.log(msg).catch(() => {});
      // log also to console if the log level is >= Error or logEverythingToConsole flag set to true
      if (logLevel >= LogLevels.ERROR || logEverythingToConsole) {
        Console()
          .log(msg)
          .catch(() => {});
      }
    }
  };

  return {
    /**
     * set log level. default: {@link LogLevels.INFO}
     * @param level {@link LogLevels}
     */
    setLogLevel: (level: LogLevels) => {
      curLogLevel = level || LogLevels.INFO;
    },
    /**
     * get current log level
     * @returns current log level. {@link LogLevels}
     */
    getLogLevel: () => curLogLevel,
    /**
     * Change logger properties after creating it
     * @param options {@link RuntimeLoggerOptions}
     * @param options.environment
     * @param options.instanceId
     * @param options.customerId
     * @param options.userId
     * @param options.appVersion
     */
    setOptions: ({
      environment = baseLogObject.environment,
      instanceId = baseLogObject.instanceId,
      customerId = baseLogObject.customerId,
      userId = baseLogObject.userId,
      appVersion = baseLogObject.appVersion,
    }: RuntimeLoggerOptions): void => {
      baseLogObject.environment = environment;
      baseLogObject.instanceId = instanceId;
      baseLogObject.customerId = customerId;
      baseLogObject.userId = userId;
      baseLogObject.appVersion = appVersion;
    },
    /**
     * log debug message
     */
    debug: log.bind(null, LogLevels.DEBUG),
    /**
     * log info message
     */
    info: log.bind(null, LogLevels.INFO),
    /**
     * log audit message
     */
    audit: log.bind(null, LogLevels.AUDIT),
    /**
     * log warn message
     */
    warn: log.bind(null, LogLevels.WARN),
    /**
     * log error message
     */
    error: log.bind(null, LogLevels.ERROR),
    /**
     * log fatal message
     */
    fatal: log.bind(null, LogLevels.FATAL),
  };
};
