import { LogLevels } from './logLevel.js';
import { browserDetails } from './browserDetails.js';

/**
 * Environments
 */
export enum Environment {
  PROD = 'prod',
  STAGE = 'stage',
  QA = 'qa',
  DEV = 'dev',
  PEG = 'peg',
}

/**
 * base log schema fields
 */
export type BaseLogSchema = {
  /**
   * application log index. required
   */
  index: string;
  /**
   * name of the current host or container
   */
  host: string;
  /**
   * name of the current environment
   */
  environment: Environment;
  /**
   * name of the current application. required
   */
  appName: string;
  /**
   * version of the current application
   */
  appVersion: string;
  /**
   * team that owns the application. required
   */
  team: string;
  /**
   * aws region or datacenter identifier where the application is running
   */
  location: string;
  /**
   * unique identifier for the logger instance
   */
  correlationId: string;
  /**
   * application instance identifier. e.g: Encompass Instance ID
   */
  instanceId: string;
  /**
   * tenant identifier. e.g: Encompass Customer ID
   */
  customerId: string;
  /**
   * user identifier. e.g: Encompass User ID
   */
  userId: string;
  /**
   * user agent details
   */
  context: typeof browserDetails;
};

/**
 * Logger options
 */
export type LoggerOptions = {
  /**
   * application log index. required
   */
  index: string;
  /**
   * name of the current host or container
   */
  host?: string;
  /**
   * name of the current environment
   */
  environment?: Environment;
  /**
   * name of the current application. required
   */
  appName: string;
  /**
   * version of the current application
   */
  appVersion?: string;
  /**
   * team that owns the application. required
   */
  team: string;
  /**
   * aws region or datacenter identifier where the application is running
   */
  location?: string;
  /**
   * application instance identifier. e.g: Encompass Instance ID
   */
  instanceId?: string;
  /**
   * tenant identifier. e.g: Encompass Customer ID
   */
  customerId?: string;
  /**
   * user identifier. e.g: Encompass User ID
   */
  userId?: string;
  /**
   * object that receives and processes the logs. default: Console. see {@link http}, {@link Console}
   */
  transport?: Transport;
  /**
   * log message to console in addition to sending them to transport layer. default: false
   */
  logEverythingToConsole?: boolean;
};

/**
 * logger options that can be changed at runtime
 */
export type RuntimeLoggerOptions = Pick<
  LoggerOptions,
  'environment' | 'instanceId' | 'customerId' | 'userId' | 'appVersion'
>;

/**
 * log message transport layer
 */
export type Transport = {
  /**
   * process log message
   * @param msg {@link LogMessage}
   */
  log: (msg: LogMessage) => Promise<unknown>;
};

/**
 * message key value pairs
 */
type LogData = Record<string, unknown>;

/**
 * message base object
 */
export type BaseRecordObject = {
  /**
   * message string
   */
  message: string;
  /**
   * error object if any
   */
  exception?: Error;
};

/**
 * message object
 */
export type LogRecord = BaseRecordObject & LogData;

/**
 * log data with log level
 * @param logLevel {@link LogLevels}
 * @param message data to log. can be a string or {@link LogRecord}
 */
export type LogFunctionWithLogLevel = (
  logLevel: LogLevels,
  message: LogRecord | string,
) => void;

/**
 * log data
 * @param message data to log. can be a string or {@link LogRecord}
 */
export type LogFunction = (message: LogRecord | string) => void;

/**
 * Logger
 */
export type Logger = {
  /**
   * set log level. default: {@link LogLevels.INFO}
   */
  setLogLevel: (level: LogLevels) => void;
  /**
   * get current log level
   * @returns current log level. {@link LogLevels}
   */
  getLogLevel: () => LogLevels;
  /**
   * Change logger properties post initialization
   * @param options {@link RuntimeLoggerOptions}
   */
  setOptions: (options: RuntimeLoggerOptions) => void;
  /**
   * log debug message
   */
  debug: LogFunction;
  /**
   * log info message
   */
  info: LogFunction;
  /**
   * log warning message
   */
  warn: LogFunction;
  /**
   * log error message
   */
  error: LogFunction;
  /**
   * log fatal message
   */
  fatal: LogFunction;
  /**
   * log audit message
   */
  audit: LogFunction;
};

/**
 * log message object received by transport layer
 */
export type LogMessage = BaseLogSchema & {
  /**
   * time at which the log message was processed
   */
  '@timestamp': string;
  /**
   * log level of the message. {@link LogLevels}
   */
  level: keyof typeof LogLevels;
  /**
   * log message
   */
  message: string;
  /**
   * The type of resource that the current request/message is working on: loan, contact, agent, etc.
   */
  resourceType?: string;
  /**
   * The ID of the resource that the current request/message is working on. Only applicable when resourceType is also present
   */
  resourceId?: string;
} & LogData;
