import { LoggerOptions, LogRecord } from './types.js';

export const validateOptions = ({
  index,
  team,
  appName,
  transport,
}: LoggerOptions): void => {
  if (!index) {
    throw new Error('index is required');
  }
  if (!team || !appName)
    throw new Error(
      'one of the following mandatory options are missing: team, appName',
    );

  if (!transport?.log) {
    throw new Error('transport should be of type Console or http');
  }
};

// eslint-disable-next-line complexity
export const validateMessage = (logMessage: string | LogRecord): void => {
  if (
    !logMessage ||
    (typeof logMessage !== 'object' && typeof logMessage !== 'string') ||
    typeof logMessage === 'function' ||
    Array.isArray(logMessage)
  )
    throw new Error('input needs to be a valid log object or string');
  if (typeof logMessage === 'object') {
    if (!logMessage.message) throw new Error('message is required');
    const { exception } = logMessage;
    if (exception && !(exception.message && exception.stack))
      throw new Error('exception should be of type Error');
  }
};
