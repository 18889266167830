import { v4 as uuid4 } from 'uuid';
import { browserDetails } from './browserDetails.js';
import { BaseLogSchema, Environment, LoggerOptions } from './types.js';

export const initBaseLogObject = (
  options: Omit<LoggerOptions, 'transport'>,
): BaseLogSchema => ({
  index: options.index,
  host: options.host || 'NA',
  environment: options.environment || Environment.DEV,
  team: options.team,
  appName: options.appName,
  appVersion: options.appVersion || '',
  location: options.location || 'NA',
  instanceId: options.instanceId || '',
  customerId: options.customerId || '',
  userId: options.userId || '',
  correlationId: uuid4().replace(/-/g, ''),
  context: browserDetails,
});
