type Options = {
  url: string;
  options: RequestInit;
  retries: number;
};

const fetchRetry = async ({
  url,
  options,
  retries,
}: Options): Promise<string> => {
  try {
    const res = await fetch(url, options);
    if (res.ok) {
      const data = await res.text();
      return data;
    }
    if (retries === 1)
      throw new Error(`status: ${res.status}, ${res.statusText}`);
    return await fetchRetry({ url, options, retries: retries - 1 });
  } catch (err) {
    if (retries === 1) throw err;
    return await fetchRetry({ url, options, retries: retries - 1 });
  }
};

export default fetchRetry;
